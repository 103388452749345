import React, { useState } from 'react';
import { get } from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';
import BitaInput from '../../../components/BitaInput/BitaInput';
import BitaSelect from '../../../components/BitaSelect/BitaSelect';
import { CURRENCIES_ARRAY } from '../utils/constants';
import { ReactComponent as Logo } from '../../../static/img/Icons/BITA.svg';
import '../Portfolio.css';

const ISIN = 'isin';
const SEDOL = 'sedol';

const DATA_OPTIONS = ['isin', 'sedol', 'cash'];

const DATA_OPTIONS_WITHOUT_CASH = ['isin', 'sedol'];

const getInstrument = (tokenUser, type, identifier) => {
  return get(
    `${URL_CONSTANTS.CONSULT_INSTRUMENT.replace('{{TYPE}}', type).replace(
      '{{VALUE}}',
      identifier,
    )}`,
    {
      headers: { Authorization: `Bearer ${tokenUser}` },
    },
  );
};

export const AddInstrumentModal = ({
  isOpen,
  onClose,
  addInstrument,
  cashOption,
  listInstruments,
  listMic,
}) => {
  const token = useSelector(state => state.auth.token);
  const [optionSelect, setOptionSelect] = useState(ISIN);
  const [identifier, setIdentifier] = useState('');
  const [mic, setMic] = useState('');

  const [data, setData] = useState({});

  const cleanData = () => {
    setData({});
    setIdentifier('');
    setOptionSelect(ISIN);
    setMic('');
  };

  const handleAddInstrument = close => {
    addInstrument({
      isin: data.isin,
      awf: 1,
      iwf: 1,
      shares: 0,
      exchange: data.mic,
      sedol: data.sedol,
      company_name: data.company_name ?? '-',
      ticker: data.ticker ?? '-',
      type: optionSelect === 'cash' ? 'with_coverage' : data.type ?? 'with_coverage',
    });
    cleanData();
    if (close) {
      onClose();
    }
  };

  const handleClose = () => {
    cleanData();
    onClose();
  };

  const validateInstrument = () => {
    let queryIdentifier = identifier;
    const isInstrumentRepeated = listInstruments.some(
      item =>
        (optionSelect === ISIN && item.isin === identifier) ||
        (optionSelect === SEDOL && item.sedol === identifier),
    );

    if (isInstrumentRepeated) {
      toast.error('The instrument is repeated, please try another one.');
      return;
    }

    if (optionSelect === ISIN) {
      queryIdentifier = `${identifier}-${mic}`;
    }

    getInstrument(token, optionSelect, queryIdentifier)
      .then(rsp => {
        const instrumentData = rsp.data.data;

        if (instrumentData.length === 0) {
          toast.error('The instrument does not exist.');
        } else {
          setData({
            ...instrumentData[0],
            shares: 0,
            awf: 1,
            iwf: 1,
          });
        }
      })
      .catch(() => {
        toast.error('Error retrieving data for this instrument.');
      });
  };

  return (
    <BitaModal isModalOpen={isOpen} setModalState={handleClose}>
      <div style={{ width: '600px', minHeight: '130px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #eee',
            padding: '10px 0',
          }}
        >
          <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
          <h2
            style={{
              margin: '0',
              padding: '0',
              fontSize: '20px',
              textTransform: 'uppercase',
              textAlign: 'center',
              marginLeft: '-20px',
              width: '100%',
            }}
          >
            Add Instrument
          </h2>
          <span
            onClick={handleClose}
            style={{ cursor: 'pointer', fontSize: '24px', lineHeight: '1' }}
          >
            &times;
          </span>
        </div>
        <div>
          <div className="addModalInstrument content" style={{ marginTop: '20px' }}>
            <div style={{ fontSize: '12px' }}>
              Enter the information to validate your instrument
            </div>
            <div>
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                {(cashOption ? DATA_OPTIONS : DATA_OPTIONS_WITHOUT_CASH).map(item => (
                  <div>
                    <input
                      id={`download-center${item}`}
                      type="radio"
                      onClick={() => {
                        setOptionSelect(item);
                        setData({});
                        setMic('');
                        setIdentifier('');
                      }}
                      checked={optionSelect === item}
                    />
                    <label htmlFor={`download-center${item}`}>{item.toUpperCase()}</label>
                  </div>
                ))}
              </div>
            </div>
            {optionSelect !== 'cash' && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <BitaInput
                  style={{ background: 'transparent', marginLeft: '0px', marginBottom: '20px' }}
                  value={identifier}
                  inputStyle="inputIdentifier"
                  justify="flex-start"
                  placeholder={optionSelect}
                  width="100%"
                  handleChange={val => setIdentifier(val)}
                />
                {optionSelect === ISIN && (
                  <BitaSelect
                    data={(listMic ?? []).map(item => {
                      return { label: item, value: item };
                    })}
                    clearable={false}
                    value={mic !== '' ? { label: mic, value: mic } : ''}
                    handleSelection={val => setMic(val.value)}
                    label="Select MIC"
                  />
                )}
                <BitaButton
                  onClick={validateInstrument}
                  width="150px"
                  disabled={
                    (optionSelect === ISIN && (identifier === '' || mic === '')) ||
                    (optionSelect === SEDOL && identifier === '')
                  }
                  height="30px"
                  style={{ margin: '5px', marginTop: '20px' }}
                  primary
                >
                  Validate
                </BitaButton>
              </div>
            )}
            <div style={{ marginTop: '30px' }}>
              <div style={{ borderBottom: '1px solid white', marginBottom: '10px' }}>
                Data Instrument
              </div>
              {optionSelect === 'cash' ? (
                <div>
                  <BitaSelect
                    label="Select Currency"
                    externalPlaceholder="Select Currency"
                    data={CURRENCIES_ARRAY.map(item => {
                      return { label: `CASH_${item}`, value: `CASH_${item}` };
                    })}
                    clearable={false}
                    value={
                      data?.isin && data?.isin !== '' ? { label: data.isin, value: data.isin } : ''
                    }
                    handleSelection={val => {
                      setData({
                        isin: val.value,
                        sedol: val.value,
                        ticker: '-',
                        company_name: '-',
                        mic: 'CASH',
                        exchange: 'CASH',
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="contentInputsAddInstrument">
                  <BitaInput
                    value={data?.isin || ''}
                    type="text"
                    title="ISIN"
                    externalTitle="ISIN"
                    placeholder="ISIN"
                    justify="flex-start"
                    handleChange={value => {}}
                  />
                  <BitaInput
                    value={data?.sedol || ''}
                    type="text"
                    title="SEDOL"
                    externalTitle="SEDOL"
                    placeholder="SEDOL"
                    justify="flex-start"
                    handleChange={value => {}}
                  />
                  <BitaInput
                    value={data?.ticker || ''}
                    type="text"
                    title="TICKER"
                    externalTitle="TICKER"
                    placeholder="TICKER"
                    justify="flex-start"
                    handleChange={value => {}}
                  />
                  <BitaInput
                    value={data?.company_name || ''}
                    type="text"
                    title="COMPANY NAME"
                    externalTitle="COMPANY NAME"
                    placeholder="COMPANY NAME"
                    justify="flex-start"
                    handleChange={value => {}}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <BitaButton
              onClick={() => handleAddInstrument(true)}
              disabled={!(data?.isin || data?.sedol)}
              width="170px"
              height="30px"
              style={{ margin: '5px', marginRight: '20px' }}
              primary
            >
              Save and Close
            </BitaButton>

            <BitaButton
              onClick={() => handleAddInstrument(false)}
              disabled={!(data?.isin || data?.sedol)}
              width="170px"
              height="30px"
              style={{ margin: '5px' }}
              primary
            >
              Save and Add Another
            </BitaButton>
          </div>
        </div>
      </div>
    </BitaModal>
  );
};
