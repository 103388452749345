import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import FileSaver from 'file-saver';
import { get, post } from 'axios';
import { useHistory } from 'react-router';
import Papa from 'papaparse';
import { BiSolidError, BiX, BiCheck } from 'react-icons/bi';
import { FaCheckSquare } from 'react-icons/fa';

import Table from './Components/EditTable';
import { uploadFileSettings, coverageRequest, updateBasket } from './utils/api';
import BitaInput from '../../components/BitaInput/BitaInput';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import BitaSpinner from '../../components/BitaSpinner/BitaSpinner';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';
import { toastInterceptor, hasValidUBSAPACOnly, hasValidBitaDomain } from '../../utils/utils';
import {
  CURRENCIES_ARRAY,
  BUSINESS_DAYS,
  DATA_APPLICATION_POINT,
  DATA_CASH_DISTRIBUTION,
} from './utils/constants';
import { LIST_TIMEZONE_LIVE_BASKET } from '../../constants/timezome-list-constants';
import { URL_CONSTANTS } from '../../api-data-mapping/url-constants';
import './Portfolio.css';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import BitaSearchBar from '../../bitaComponents/BitaSearchBar/BitaSearchBar';
import BitaCalendarDate from '../../components/BitaCalendar/BitaCalendarDate';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';
import Switcher from '../../bitaComponents/Switcher/Switcher';
import VisualizationModal from './withholding-tax/VisualizationModal';
import ShowPreview from './utils/ShowPreview';
import { isFloatWithMoreThanTwoDecimals, existRequestCoverageWithData, orderByType } from './utils';

import style from './update-basket.module.scss';
import styles from './PortfolioBasketCalculation.module.scss';
import tableStyles from './table.module.scss';
import { AddInstrumentModal } from './Modals/AddInstrumentModal';

const getBasket = (tokenUser, data) => {
  return get(URL_CONSTANTS.GETLIVEBASKET, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const getBasketById = (tokenUser, basket) => {
  return get(`${URL_CONSTANTS.GETLIVEBASKET}/${basket}`, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const getMIC = (tokenUser, data) => {
  return get(URL_CONSTANTS.ACE_MIC_ALL_URL, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const recalculateCoverage = (tokenUser, data) => {
  return post(URL_CONSTANTS.COVERAGE_REQUEST_UPDATE_URL, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const url = `${process.env.REACT_APP_API_URL}/ace/basket/mic/all`;

function truncateString(str, limit) {
  if (str.length > limit) {
    return `${str.substring(0, limit)}...`;
  }
  return str;
}

const basketButton = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  Global: [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};

const CALCULATION_ZONE_MAPPING = {
  us_only: 'US / Eastern',
  us_europe: 'US / Europe',
  global: 'Global',
  europe_only: 'Europe only',
  eastern_apac: 'Eastern Asia Pacific',
};

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const formatDateString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

// Verifies that a date is from today or later (and not a date in the past). For example: valid dates: today date, tomorrow date. Invalid dates: yesterday date, last month date, etc.
function isValidDateString(dateString) {
  // Check if the date string matches the format yyyy-mm-dd
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date string into a Date object
  const date = new Date(`${dateString}T00:00:00`); // Treat as local midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to local midnight

  // Check if the date is today or in the future
  return date >= today;
}

// TODO improve this with the isValidDateString
const isValidDateFormat = dateString => {
  let isValid = false;
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    isValid = false;
  }

  const date = new Date(dateString);
  isValid = date.toISOString().slice(0, 10) === dateString;

  return isValid;
};

const timezone = LIST_TIMEZONE_LIVE_BASKET.sort().map(item => {
  return {
    value: item.region,
    id: item.region,
    name: item.region,
    timezone: item.timezone,
    calculationZone: item.calculationZone,
  };
});

const validatedFieldsValues = {
  currency: CURRENCIES_ARRAY,
  divisor_determination_setup: ['PREVIOUS EOD', 'IMMEDIATELY'],
  calculation_setup: ['EOD', 'EOD_INTRADAY'],
  calculation_zone: ['us_only', 'us_europe', 'global', 'europe_only', 'eastern_apac'],
  deployment_setup: ['EOD', 'IMMEDIATELY'],
  calculation_type: ['NET_RETURN', 'TOTAL_RETURN', 'PRICE_RETURN'],
  cash_distribution: [
    'DISTRIBUTION_ACROSS_BASKET',
    'DISTRIBUTION_ON_PAYING_SECURITY',
    'ADD_DISTRIBUTION_TO_CASH',
  ],
  application_point: ['AT_MARKET_CLOSE', 'AT_MARKET_OPEN'],
  dissemination: ['BITA Only'],
};

const INITIAL_PAGINATION = {
  pageIndex: 0,
  pageSize: 10,
};

const validateData = [
  'timezone',
  'divisor',
  'calculation_setup',
  'deployment_setup',
  'currency',
  'calculation_type',
  'calculation_zone',
  'cash_distribution',
  'application_point',
  'dissemination',
];

const validateDataLabel = {
  timezone: 'Timezone',
  symbol: 'Symbol',
  divisor: 'Divisor',
  calculation_setup: 'Calculation frequency',
  deployment_setup: 'Launch Point',
  currency: 'Currency',
  calculation_type: 'Return calculation',
  calculation_zone: 'Calculation Zone',
  cash_distribution: 'Cash Distribution',
  application_point: 'Application Point',
  dissemination: 'Dissemination',
};

const UpdateBasket = () => {
  const authOrgPrefix = useSelector(state => state.auth?.org_prefix);
  const [files, setFiles] = React.useState('');
  const user = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [type, setType] = useState('Long only Basket');
  const [data, setData] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [previousData, setPreviousData] = useState();
  const [dataBacktest, setDataBacktest] = useState([]);
  const [dataToshowOnSummary, setDataToshowOnSummary] = useState();
  const [errorReferenceData, setErrorReferenceData] = useState(false);
  const [idIndex, setIdIndex] = useState();
  const [selectBasket, setSelectBasket] = useState();
  const [filesBacktest, setFilesBacktest] = React.useState('');
  const [listBasket, setListBasket] = useState([]);
  const [listMIC, setListMIC] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(Date.now());
  const [constituentsDelete, setConstituentsDelete] = useState([]);
  const [showModalRequestCoverage, setShowModalRequestCoverage] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);

  const [showModalRequestCoverageSuccessful, setShowModalRequestCoverageSuccessful] = useState(
    false,
  );
  const [enableDivisorOrStartingValue, setEnableDivisorOrStartingValue] = useState({
    statusDivisor: true,
    statusStarting: false,
  });
  const { token } = user;
  const [launchDate, setLaunchDate] = useState(null); // Now the initial state is null instead of today date
  const [errorCalendar, setErrorCalendar] = useState(false);
  const [isActiveAnnualizedYearlyDecrement, setIsActiveAnnualizedYearlyDecrement] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [openWithholingModal, setOpenWithholdingModal] = useState(false);

  const inputBasketRef = useRef(null);
  const inputBacktestRef = useRef(null);

  const history = useHistory();

  const getTemplates = () => {
    return get(URL_CONSTANTS.WITHHOLDING_TAX, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  useEffect(() => {
    if (data?.meta_reference_data?.length > 0) {
      setIsDataLoading(false);
    }
  }, [data]);

  const cleanStates = () => {
    setShowModal(false);
    setShowModal(false);
    // setData(null);
    setFiles('');
    setFilesBacktest('');

    // new states to clean
    setErrorCalendar(false);
    setLaunchDate(null);

    setShowModalDelete(false);
    setShowModalRequestCoverageSuccessful(false);
    setShowModalRequestCoverage(false);
    setShowModalConfirm(false);
    setDataBacktest([]);
    setConstituentsDelete([]);
    // setConstituentsError([]);
    setIsActiveAnnualizedYearlyDecrement(false);
    setData({});
    setType('Long only Basket');
  };

  // Add a custom row to remove the U icon in the first column
  const CustomRow = ({ entryKey, value }) => {
    return (
      <div>
        <p>
          {entryKey === 'weights' && typeof value === 'number'
            ? value.toFixed(6)
            : isFloatWithMoreThanTwoDecimals(value) || value}
        </p>
      </div>
    );
  };

  const getColumns = valueData => {
    const columns = [
      'isin',
      'sedol',
      ...Object.keys(valueData?.[0] ?? {})
        .map(column => (column === 'exchange' ? 'MIC' : column))
        .filter(item => item !== 'isin' && item !== 'sedol'),
    ];

    if (authOrgPrefix === 'UB') {
      const element = 'sedol';
      const index = columns.indexOf(element);
      if (index !== -1) {
        columns.splice(index, 1);
        columns.unshift(element);
      }
    }
    return columns;
  };

  const updateRequestCoverage = calculation_frequency => {
    recalculateCoverage(token, {
      constituents: data?.meta_reference_data.map(item => {
        return {
          isin: item.isin,
          exchange: item?.exchange,
        };
      }),
      calculation_setup: calculation_frequency,
    })
      .then(rsp => {
        if (!rsp.data.data.full_coverage) {
          setData(prev => {
            return {
              ...prev,
              meta_reference_data: prev?.meta_reference_data.map(constituent => {
                return {
                  ...constituent,
                  type: rsp.data.data.request_coverage
                    ?.map(item => item.isin)
                    ?.includes(constituent?.isin ?? constituent?.sedol)
                    ? 'request_coverage'
                    : 'with_coverage',
                };
              }),
            };
          });
        }
      })
      .catch(error => {
        console.info(error);
      });
  };

  const getColumnsUpdateTable = valueData => {
    const columns = Object.keys(valueData?.[0] ?? {}).map(column =>
      column === 'exchange' ? 'MIC' : column,
    );
    if (authOrgPrefix === 'UB') {
      const element = 'sedol';
      const index = columns.indexOf(element);
      if (index !== -1) {
        columns.splice(index, 1);
        columns.unshift(element);
      }
    }
    return columns;
  };

  const transformRow = (row, orderColumns) => {
    const newRow = {
      isin: row.isin,
      sedol: row.sedol || '-',
      MIC: row.exchange,
      ...Object.fromEntries(
        Object.entries(row).filter(([key]) => key !== 'sedol' && key !== 'isin'),
      ),
    };
    return orderColumns.reduce((acc, column) => {
      acc[column] = newRow[column] ?? '-';

      return acc;
    }, {});
  };

  const renderRows = valueData => {
    const orderColumns = getColumns(selectBasket?.meta_reference_data);
    const dataRows = valueData ?? [];
    return dataRows.map(row => {
      const newRow = transformRow(row, orderColumns);
      return newRow;
    });
  };

  const renderRowsUpdateTable = valueData => {
    const dataRows = valueData ?? [];
    if (authOrgPrefix === 'UB') {
      return dataRows.map(row => {
        if (row.sedol) {
          return { sedol: row.sedol, ...row, MIC: row.exchange };
        }
        return { ...row, MIC: row.exchange };
      });
    }
    return dataRows.map(row => ({ sedol: '-', ...row, MIC: row.exchange }));
  };

  useEffect(() => {
    getMIC(token)
      .then(rsp => {
        setListMIC(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });
    getBasket(token)
      .then(rsp => {
        setListBasket(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });

    getTemplates()
      .then(rsp => {
        const dataTax = [
          ...rsp?.data?.data,
          {
            withholding_tax_name: 'BITA Withholding Taxes',
            id: 'BITA Withholding Taxes',
            custom: 'false',
          },
        ];
        setTemplateData(dataTax);
      })
      .catch(error => {
        toastInterceptor(error);
      });
  }, []);

  const processDataArray = () => {
    if (data?.meta_reference_data?.length > 0 && previousData?.meta_reference_data?.length > 0) {
      const result = [];
      const oldData = {};

      // eslint-disable-next-line no-unused-expressions
      previousData?.meta_reference_data?.forEach(item => {
        oldData[item?.isin] = item;
      });
      // eslint-disable-next-line no-unused-expressions
      data?.meta_reference_data.forEach(item => {
        let typeOfModification = 'no_change';
        const oldInstrument = oldData?.[item.isin];
        if (oldInstrument) {
          if (oldInstrument?.exchange !== item.exchange || oldInstrument?.shares !== item.shares) {
            typeOfModification = 'Update';
          }
          delete oldData?.[item.isin];
        } else {
          typeOfModification = 'Addition';
        }

        if (typeOfModification !== 'no_change') {
          let entry;
          if (authOrgPrefix === 'UB') {
            entry = {
              SEDOL: item?.sedol ?? '-',
              ISIN: item?.isin ?? '-',
              ticker: item.ticker ?? '-',
              'OLD MIC': oldInstrument?.exchange ?? '-',
              'NEW MIC': item.exchange ?? '-',
              'OLD SHARES': oldInstrument?.shares ?? '-',
              'NEW SHARES': item.shares ?? '-',
              type: typeOfModification,
            };
          } else {
            entry = {
              ISIN: item?.isin ?? '-',
              SEDOL: item?.sedol ?? '-',
              ticker: item.ticker ?? '-',
              'OLD MIC': oldInstrument?.exchange ?? '-',
              'NEW MIC': item.exchange ?? '-',
              'OLD SHARES': oldInstrument?.shares ?? '-',
              'NEW SHARES': item.shares ?? '-',
              type: typeOfModification,
            };
          }
          result.push(entry);
        }
      });

      Object.keys(oldData).forEach(key => {
        let entry;
        if (authOrgPrefix === 'UB') {
          entry = {
            SEDOL: oldData[key]?.sedol ?? '-',
            ISIN: oldData[key]?.isin ?? '-',
            ticker: oldData[key]?.ticker ?? oldData[key]?.isin,
            'OLD MIC': oldData[key]?.exchange ?? '-',
            'NEW MIC': '-',
            'OLD SHARES': oldData[key]?.shares ?? '-',
            'NEW SHARES': '-',
            type: 'Delete',
          };
        } else {
          entry = {
            ISIN: oldData[key]?.isin ?? '-',
            SEDOL: oldData[key]?.sedol ?? '-',
            ticker: oldData[key]?.ticker ?? oldData[key]?.isin,
            'OLD MIC': oldData[key]?.exchange ?? '-',
            'NEW MIC': '-',
            'OLD SHARES': oldData[key]?.shares ?? '-',
            'NEW SHARES': '-',
            type: 'Delete',
          };
        }
        result.push(entry);
      });
      setDataToshowOnSummary([...result]);
    }
  };

  const resetBasketData = () => {
    setData(null);
    setFiles('');
    setSelectBasket({ value: '' });
    setFilesBacktest('');
  };

  const handleSubmitButton = () => {
    const formData = new FormData();
    const updateData = {
      ...data,
      additional_settings: {
        ...data?.additional_settings,
      },
      withholding_tax_id:
        !data?.withholding_tax_id || data?.withholding_tax_id === 'BITA withholding taxes'
          ? null
          : data?.withholding_tax_id,
      m_withholding_tax_id:
        !data?.withholding_tax_id || data?.withholding_tax_id === 'BITA withholding taxes'
          ? null
          : data?.withholding_tax_id,
      basket_id: selectBasket.id,
      symbol: data.symbol,
      effective_date: formatDateString(effectiveDate),
      metadata: [...data?.meta_reference_data],
      divisor: data?.divisor,
      decrement_basket: {
        annualized_daily_decrement: isActiveAnnualizedYearlyDecrement,
        decrement_value_percentage: isActiveAnnualizedYearlyDecrement
          ? Number(data?.percentage_value)
          : 0,
      },
      divisor_determination_setup: data?.divisor_determination_setup?.replace(' ', '_'),
    };
    delete updateData.additional_settings.annualized_daily_decrement;
    delete updateData.additional_settings.decrement_value_percentage;
    delete updateData.percentage_value;

    if (filesBacktest) {
      formData.append('template', filesBacktest);
      formData.append('symbol', selectBasket.symbol);
    }
    formData.append('data', JSON.stringify(updateData));

    updateBasket(token, formData)
      .then(rsp => {
        setShowModalConfirm(false);
        toast('Your Basket has been processed successfully');

        setShowModal(true);
        setIdIndex(selectBasket?.id);
        if (inputBacktestRef && inputBacktestRef.current) {
          inputBacktestRef.current.value = '';
        }
        getBasket(token)
          .then(resp => {
            setListBasket(Object.values(resp?.data?.data));
          })
          .catch(error => {
            toastInterceptor(error);
          });

        // resetBasketData(); // Reset the state after successfully processed the basket
      })
      .catch(error => {
        toastInterceptor(error);
      });
  };

  const existRequestCoverage = () => {
    let coverage = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.meta_reference_data?.length; i++) {
      if (data?.meta_reference_data[i]?.type !== 'with_coverage') {
        coverage = true;
        break;
      }
    }
    return coverage;
  };

  const BacktestTable = () => {
    const renderTable = () => {
      const columns = Object.keys(dataBacktest?.[0] ?? {});
      const rows = dataBacktest.slice(1);

      return (
        <div style={{ height: '340px' }}>
          <div style={{ border: 'none', marginBottom: '0px' }} className={tableStyles.tableTitle}>
            Backtest Data Preview
          </div>
          <BitaTable
            data={rows}
            search={false}
            showFields={columns}
            height="300px"
            showOnly={10}
            pagination
            selectAction={() => {}}
            CustomRowComponent={({ value }) => (
              <div>
                <p style={{ paddingRight: '20px' }}>{value}</p>
              </div>
            )}
          />
        </div>
      );
    };
    return <div className={style.contentWidget}>{renderTable()}</div>;
  };

  const BasketTable = ({ titleBasketTable, deleteBasketTable, buttonsBasketTable, noScroll }) => {
    const renderTable = () => {
      if (type === 'Long only Basket' && data.meta_reference_data) {
        const updatedRows = data.meta_reference_data;
        const columns = Object.keys(data.meta_reference_data?.[0] ?? {});

        return (
          <div className="contentTableBasketPreview">
            {titleBasketTable && (
              <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
            )}
            {titleBasketTable && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1>General file information section</h1>
              </div>
            )}
            {noScroll ? (
              <> </>
            ) : (
              <BitaTable
                id="basket"
                data={updatedRows ?? []}
                height="100%"
                pagination
                style={{ padding: '0' }}
                search={false}
                scroll={!noScroll}
                showFields={deleteBasketTable ? [...(columns ?? [])] : columns ?? []}
                selectAction={() => {}}
                editButton={false}
                CustomRowComponent={({ data: dataValue, entryKey, value }) => (
                  <div
                    className="custom-row-container"
                    style={{ width: entryKey === 'ticker' ? '100px' : '' }}
                  >
                    <p className={`${entryKey === 'type' ? 'info-icon-live' : ''}`}>
                      {entryKey === 'type' && deleteBasketTable ? (
                        value === 'with_coverage' ? (
                          <FaCheckSquare className="icon-info" />
                        ) : (
                          <BiSolidError className="icon-error" />
                        )
                      ) : entryKey === 'isin' && deleteBasketTable ? (
                        <div style={{ display: 'flex' }}>
                          <BitaCheckbox
                            checked={constituentsDelete.includes(
                              dataValue?.isin ?? dataValue?.sedol,
                            )}
                            handleChange={checked => {
                              if (checked) {
                                setConstituentsDelete([
                                  ...constituentsDelete,
                                  dataValue?.isin ?? dataValue?.sedol,
                                ]);
                              } else {
                                setConstituentsDelete(
                                  constituentsDelete.filter(
                                    item => item !== (dataValue?.isin ?? dataValue?.sedol),
                                  ),
                                );
                              }
                            }}
                          />
                          {value}
                        </div>
                      ) : (
                        value ?? '-'
                      )}
                    </p>
                    {entryKey === 'type' && value !== 'with_coverage' && (
                      <div className="tooltip error-tooltip">
                        <div>
                          <BiX className="tooltip-icon icon-error" />
                        </div>
                        <span>
                          This instrument is not under the BITA Calculation platform pricing
                          coverage. You can request the coverage using the button below &quot;
                          Request Coverage &quot;. We will receive the request, process it, and
                          communicate with you via e-mail as soon as possible.
                        </span>
                      </div>
                    )}
                    {entryKey === 'type' && value === 'with_coverage' && (
                      <div className="tooltip success-tooltip">
                        <div>
                          <BiCheck className="tooltip-icon icon-info" />
                        </div>
                        <span>The input has been successfully validated</span>
                      </div>
                    )}
                  </div>
                )}
                // eslint-disable-next-line no-shadow
              />
            )}
          </div>
        );
      }

      if (type === 'Decrement Index') {
        return <></>;
      }
      return null;
    };

    return <div className={titleBasketTable ? '' : style.contentWidget}>{renderTable()}</div>;
  };

  const handleChange = (name, value) => {
    if (name === 'calculationZone') {
      const timeData = LIST_TIMEZONE_LIVE_BASKET.find(item => item.region === value);
      setData({
        ...data,
        region: value,
        timezone: timeData?.timezone,
        calculation_close_time: timeData?.calculationEnd,
        calculation_open_time: timeData?.calculationStart,
        calculation_zone: timezone.find(item => item?.name === value)?.calculationZone,
      });
    } else if (name === 'calculation_type' && value === 'PRICE_RETURN') {
      setData({
        ...data,
        additional_settings: {
          ...data?.additional_settings,
          cash_distribution_application_point: 'AT_MARKET_OPEN',
        },
        cash_distribution: 'DISTRIBUTION_ACROSS_BASKET',
        application_point: 'AT_MARKET_OPEN',
        [name]: value,
      });
    } else if (name === 'application_point') {
      setData({
        ...data,
        additional_settings: {
          ...data?.additional_settings,
          cash_distribution_application_point: value,
        },
        [name]: value,
      });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const validateFields = (key, field) => {
    let acceptedValues;
    if (!key || !field) return false;
    if (key === 'withholding_tax_id') {
      acceptedValues = templateData?.some(item => item.id === field);
      return acceptedValues;
    }
    acceptedValues = validatedFieldsValues[key];
    return !!acceptedValues?.includes(field);
  };

  const validarData = (dataSend, list) => {
    return list.every(propertyValidate => {
      if (propertyValidate === 'divisor') {
        // Special case for divisor: check if it exists and is a number greater than 0
        return (
          propertyValidate in dataSend &&
          typeof dataSend[propertyValidate] === 'number' &&
          dataSend[propertyValidate] > 0
        );
      }
      // For all other properties, keep the original checks
      return (
        propertyValidate in dataSend &&
        typeof dataSend[propertyValidate] === 'string' &&
        dataSend[propertyValidate].trim() !== ''
      );
    });
  };

  const handleConstituentToggle = value => {
    setConstituentsDelete(prev => {
      const exists = constituentsDelete.some(
        item =>
          (value.isin && item.isin === value.isin) || (value.sedol && item.sedol === value.sedol),
      );

      if (exists) {
        return prev.filter(
          item =>
            !(
              (value.isin && item.isin === value.isin) ||
              (value.sedol && item.sedol === value.sedol)
            ),
        );
      }
      return [...prev, value];
    });
  };

  const fieldsValidations = () => {
    const { statusDivisor, statusStarting } = enableDivisorOrStartingValue;

    try {
      if (!data) return false;

      return (
        data?.meta_reference_data &&
        validarData(
          data,
          validateData.filter(
            item => !(statusStarting && data.divisor_determination_setup && item === 'divisor'),
          ),
        ) &&
        validateFields('cash_distribution', data?.cash_distribution) &&
        validateFields('application_point', data?.application_point) &&
        validateFields('calculation_type', data?.calculation_type) // This is return type
      );
    } catch (error) {
      console.error('Validation error:', error);
      return false;
    }
  };

  // Function to validate if either the divisor or starting value is valid
  const isDivisorOrStartingValueValid = () => {
    const { divisor } = data || {};
    const { statusDivisor } = enableDivisorOrStartingValue;

    return divisor > 0 && statusDivisor;
  };

  const applyButtonValidations = () => {
    const hasLaunchDateError = errorCalendar;
    const hasReferenceDataError = errorReferenceData;
    const hasValidBasketData =
      selectBasket && (data?.meta_reference_data || dataBacktest.length > 0);
    const hasRequestCoverage = existRequestCoverage();
    const hasExchangeData = data?.meta_reference_data?.[0]?.exchange || false;
    const areFieldsValid = fieldsValidations();
    const currencyValidations = data?.currency && validateFields('currency', data?.currency);
    const hasMetadataExists = data?.meta_reference_data?.length > 0;

    // If a file is present, perform additional checks
    if (files?.name) {
      return (
        hasReferenceDataError ||
        hasLaunchDateError ||
        !hasMetadataExists ||
        !hasValidBasketData ||
        hasRequestCoverage ||
        !hasExchangeData ||
        !areFieldsValid ||
        !currencyValidations
      );
    }

    // Perform basic checks when no file is present
    return (
      hasLaunchDateError ||
      !areFieldsValid ||
      !currencyValidations ||
      hasReferenceDataError ||
      !hasMetadataExists
    );
  };

  const handleApply = () => {
    setShowModalConfirm(true);
    processDataArray();
  };

  return (
    <div>
      {/* Live Basket(s) selector and Basket Data Preview */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Live Basket(s)</div>
        <div className={styles.contentDataUpload}>
          <div style={{ marginRight: 12 }}>
            <div className={style.container}>
              <div style={{ width: '235px', paddingLeft: '5%', paddingBottom: '5%' }}>
                <div className={style.subtitle} style={{ paddingBottom: '10%' }}>
                  Basket
                </div>
                <BitaSearchBar
                  forceStopLoading
                  key="Baskets"
                  placeholder="Live Basket(s)"
                  searchOptions={listBasket?.map(item => {
                    return {
                      label: item?.symbol,
                      key: item?.id,
                      value: item?.symbol,
                      id: item?.id,
                      name: item?.symbol,
                      item,
                    };
                  })}
                  value={selectBasket?.value}
                  onChange={async selected => {
                    setFiles('');
                    const basketData = await getBasketById(token, selected?.value);
                    setSelectBasket(basketData?.data.data);
                    const getCalculationZone = () => {
                      const selectedTimezone = basketData?.data?.data?.timezone;
                      const selectedOpenTime = basketData?.data?.data?.calculation_open_time;
                      const selectedCloseTime = basketData?.data?.data?.calculation_close_time;

                      const matchingZone = LIST_TIMEZONE_LIVE_BASKET.find(zone => {
                        return (
                          zone.timezone === selectedTimezone &&
                          zone.calculationStart === selectedOpenTime &&
                          zone.calculationEnd === selectedCloseTime
                        );
                      });

                      return matchingZone ? matchingZone.calculationZone : '';
                    };

                    const getCalculationSetup = item => {
                      if (item?.calculate && item?.intraday) {
                        return 'EOD_INTRADAY';
                      }
                      return 'EOD';
                    };

                    const updatedItem = {
                      ...basketData.data.data,
                      divisor: basketData?.data?.data?.divisor || 0,
                      deployment_setup: 'EOD',
                      calculation_type:
                        basketData?.data?.data?.calculation_type?.toUpperCase() || '',
                      cash_distribution:
                        basketData?.data?.data?.additional_settings?.m_cash_distribution_type
                          ?.name || '',
                      application_point:
                        basketData?.data?.data?.additional_settings
                          ?.cash_distribution_application_point || '',
                      calculation_zone: getCalculationZone(),
                      calculation_setup: getCalculationSetup(basketData?.data?.data),
                      dissemination: 'BITA Only',
                      cash_constituent:
                        basketData?.data?.data?.additional_settings?.cash_constituent,
                      withholding_tax_id:
                        basketData?.data?.data?.additional_settings?.withholding_tax_id ??
                        basketData?.data?.data?.withholding_tax_id,
                      percentage_value:
                        basketData?.data?.data?.additional_settings?.decrement_value_percentage &&
                        basketData?.data?.data?.additional_settings?.decrement_value_percentage !==
                          0
                          ? basketData?.data?.data?.additional_settings
                              ?.decrement_value_percentage * 100
                          : 0,
                    };

                    setIsActiveAnnualizedYearlyDecrement(
                      basketData?.data?.data?.additional_settings?.annualized_daily_decrement ??
                        false,
                    );

                    setLaunchDate(basketData?.data?.data?.launch_date);
                    /* delete updatedItem.additional_settings; */
                    delete updatedItem.calculation_formula;
                    delete updatedItem.type;
                    delete updatedItem.asset_class;
                    delete updatedItem.id_m_index_parent;
                    delete updatedItem.intraday;
                    delete updatedItem.calculate;
                    setData(updatedItem); // Update the state with the new object
                    setPreviousData(updatedItem);
                  }}
                />
              </div>
            </div>
          </div>

          {selectBasket?.id && (
            <div className={styles.contentUpdateBasket}>
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <div
                className={tableStyles.tableContainer}
                style={{ display: 'flex', width: '100%' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '28%' }}>
                  <div className={style.tableNoMetadata}>
                    <div style={{ display: 'flex' }}>
                      <div>Ticker: </div> <div>{selectBasket?.symbol}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Divisor: </div> <div>{selectBasket?.divisor}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>open hour: </div> <div>{selectBasket?.calculation_open_time}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>close hour: </div> <div>{selectBasket?.calculation_close_time}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>timezone: </div> <div>{selectBasket?.timezone}</div>
                    </div>
                  </div>
                </div>
                {/* Pre visualization table */}
                <div style={{ width: '72%' }} className="tableUpdateBasket">
                  <BitaTable
                    id="basket"
                    data={renderRows(selectBasket?.meta_reference_data)}
                    search={false}
                    pagination
                    showFields={getColumns(selectBasket?.meta_reference_data)}
                    selectAction={() => {}}
                    CustomRowComponent={CustomRow}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Basket Composition/Portfolio */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Update Basket Composition/Portfolio</div>

        {/* Templates and Basket Data Preview */}
        <div className={styles.contentDataUpload}>
          <div>
            <div className={style.container}>
              <div className={style.contentTemplateUpload}>
                <div className={style.subtitle}>TEMPLATES</div>
                {Object.values(basketButton[type] ?? {}).map(button => (
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/${button?.url}.csv`,
                        `${button?.url}.csv`,
                      );
                    }}
                  >
                    {button?.label}
                  </BitaButton>
                ))}
              </div>

              <div className={style.dataUpload}>
                <div className={style.contentTemplate}>
                  <div className={style.subtitle}>PORTFOLIO UPLOADER</div>

                  <input
                    type="file"
                    onChange={event => {
                      if (selectBasket) {
                        cleanStates();
                        setFiles(event.target.files[0]); // Basket file
                      }
                    }}
                    ref={inputBasketRef}
                    name="file"
                    id="file"
                    className={style.inputfile}
                    disabled={!selectBasket}
                    style={{ display: 'none' }} // Hide the default file input
                  />
                  <label
                    htmlFor="file"
                    style={{
                      cursor: selectBasket ? 'pointer' : 'not-allowed',
                      opacity: selectBasket ? 1 : 0.6,
                      pointerEvents: selectBasket ? 'auto' : 'none',
                    }}
                  >
                    Select file
                  </label>

                  <BitaButton
                    disabled={!files}
                    onClick={() => {
                      setIsDataLoading(true);
                      const formData = new FormData();
                      formData.append('template', files);

                      if (type === 'Long only Basket') {
                        formData.append('type', 'basket');
                      } else {
                        formData.append('type', type.replace('/', '_'));
                        // formData.append(
                        //   'type',
                        //   type.includes('Bottom') ? 'global' : type.replace('/', '_'),
                        // );
                      }
                      setData({});
                      setDataBacktest([]);
                      setFilesBacktest('');
                      if (files) {
                        uploadFileSettings(token, formData)
                          .then(rsp => {
                            // Implementation like Single Basket
                            toast('Your file has been processed successfully');
                            // const constituents = Object.keys(rsp.data.data?.metadata.awf);
                            // const constituentsWithError = constituents.filter(
                            //   (constituyente, index) => index % 3 === 0,
                            // );
                            const timeData = LIST_TIMEZONE_LIVE_BASKET.find(
                              item => item.calculationZone === rsp.data.data?.calculation_zone,
                            );
                            // setConstituentsError(constituentsWithError);

                            if (existRequestCoverageWithData(rsp.data.data?.meta_reference_data)) {
                              toast.warning('Some instruments are not covered');
                            }

                            const dataFile = {
                              ...rsp.data.data,
                              meta_reference_data: orderByType(rsp.data.data?.meta_reference_data),
                              calculation_zone: validateFields(
                                'calculation_zone',
                                rsp.data.data?.calculation_zone,
                              )
                                ? rsp.data.data?.calculation_zone
                                : '',
                              region: timeData?.region,
                              timezone: timeData?.timezone,
                              calculation_close_time: timeData?.calculationEnd,
                              calculation_open_time: timeData?.calculationStart,
                              start_week_day: timeData?.startWeekDay,
                              end_week_day: timeData?.endWeekDay,
                              dissemination: 'BITA Only',
                              divisor: rsp.data.data?.divisor || 0,

                              // Required fields here
                              currency: validateFields('currency', rsp.data.data?.currency)
                                ? rsp.data.data?.currency
                                : selectBasket?.currency,

                              // Optional fields
                              name: rsp.data.data?.name || selectBasket?.name, // add name from file, if not, then add name from the current basket
                              description: rsp.data.data?.description || selectBasket?.description, // add description from file, if not, then add name from the current basket
                            };

                            if (dataFile?.starting_value) {
                              // remove starting value if comes from file
                              delete dataFile.starting_value;
                            }

                            setData(dataFile);
                            // setDataInitial(dataFile);
                            const launchDateString = rsp.data.data?.launch_date;

                            if (launchDateString) {
                              if (isValidDateFormat(launchDateString)) {
                                if (isValidDateString(launchDateString)) {
                                  // it is a valid date
                                  const date = new Date(launchDateString);
                                  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                  setLaunchDate(date);
                                  setErrorCalendar(false);
                                } else {
                                  setIsDataLoading(false);
                                  // it is not a valid date, so the user should add it manually
                                  setLaunchDate(null);
                                  setErrorCalendar(true);
                                }
                              } else {
                                setIsDataLoading(false);
                                // It is not in the correct format, so the user should add it manually
                                setLaunchDate(null);
                                setErrorCalendar(true);
                              }
                            } else {
                              setIsDataLoading(false);
                              // The launch date is not included in the API response, so add today's date
                              setLaunchDate(new Date());
                              setErrorCalendar(false);
                            }

                            inputBasketRef.current.value = '';
                          })
                          .catch(error => {
                            setIsDataLoading(false);
                            toastInterceptor(error);
                          });
                      } else {
                        setIsDataLoading(false);
                        toast.error(
                          'Please upload a portfolio template with a different file name.',
                        );
                      }
                    }}
                    primary
                    width="160px"
                    height="35px"
                  >
                    Upload File
                  </BitaButton>
                </div>
                <p className={style.nameFile}>{files && `(${files.name})`}</p>
              </div>

              <div style={{ marginLeft: '4%', width: '30%' }}>
                <BitaCalendarDate
                  title="Effective Date"
                  select={value => setEffectiveDate(value)}
                  maxDate={new Date('3000-12-12')}
                  minDate={new Date()}
                  date={effectiveDate}
                />
              </div>
            </div>
          </div>

          {/*   {data && (
            <div className="tableUpdateBasket">
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <BitaTable
                data={renderRows(data)}
                search={false}
                showFields={getColumns(data)}
                selectAction={() => {}}
                CustomRowComponent={CustomRow}
              />
            </div>
          )} */}

          <div style={{ padding: '0px 20px ' }}>
            {data?.meta_reference_data && (
              <div
                className="contentTableBasketPreview"
                style={{ marginTop: '0px', paddingTop: '0px' }}
              >
                <div className="headerTable">
                  <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>General file information section</h1>
                  </div>
                </div>
                {data && (
                  <div style={{ height: '600px' }}>
                    <div>
                      <Table
                        basket={selectBasket.symbol}
                        rows={data.meta_reference_data}
                        listMic={listMIC}
                        calculation_setup={data?.calculation_setup}
                        setMetadata={setData}
                        constituentsDelete={constituentsDelete}
                        setConstituentsDelete={value => handleConstituentToggle(value)}
                        allDelete={value => {
                          setConstituentsDelete(value);
                        }}
                        setErrorReferenceData={setErrorReferenceData}
                      />
                    </div>
                  </div>
                )}

                {files?.name && data && existRequestCoverage() && (
                  <BitaButton
                    disabled={!existRequestCoverage()}
                    primary
                    width="160px"
                    style={{ marginRight: 'auto', marginTop: '20px' }}
                    height="35px"
                    onClick={() => setShowModalRequestCoverage(true)}
                  >
                    Request Coverage
                  </BitaButton>
                )}

                {data && (
                  <BitaButton
                    disabled={constituentsDelete.length === 0}
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Delete Constituent
                  </BitaButton>
                )}
                {data && (
                  <BitaButton
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalAdd(true);
                    }}
                  >
                    Add Instrument
                  </BitaButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Backtest section */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Update Backtest</div>

        {/* Backtest Uploader */}
        <div className={styles.contentDataUpload} style={{ marginLeft: '18px' }}>
          <div className={style.dataUpload}>
            <div className={style.container}>
              <div>
                <div className={style.subtitle}>TEMPLATES</div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginTop: '10px',
                    marginBottom: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/backtest_template.csv`,
                        `backtest_template.csv`,
                      );
                    }}
                  >
                    Backtest Template
                  </BitaButton>
                </div>
              </div>
              <div className={style.subtitle}>Backtest Uploader</div>
              <div
                className={style.containerButtonsUpload}
                // style={{
                //   display: 'flex',
                //   alignItems: 'flex-end',
                //   flexWrap: 'wrap',
                //   // pointerEvents: selectBasket?.value && data ? 'auto' : 'none',
                //   // opacity: selectBasket?.value && data ? 1 : 0.5,
                // }}
              >
                {/* <div>
                  <CSVReader
                    onUploadAccepted={results => {
                      setDataBacktest(results?.data);
                    }}
                  >
                    {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                      <>
                        <div>
                          <button className={style.buttonUpload} type="button" {...getRootProps()}>
                            Select File
                          </button>
                          <div style={styles.acceptedFile} />
                        </div>
                        <div className={style.nameFile}>
                          {acceptedFile && `(${acceptedFile.name})`}
                          {acceptedFile && setFilesBacktest(acceptedFile)}
                        </div>
                      </>
                    )}
                  </CSVReader>
                </div> */}

                <div>
                  <input
                    className={style.buttonUpload}
                    type="file"
                    accept=".csv"
                    onChange={event => {
                      const file = event.target.files[0];
                      setFilesBacktest(file);
                    }}
                    style={{ display: 'none' }} // Oculta el elemento <input>
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className={style.buttonUpload}>
                    Select File
                  </label>
                  <div style={styles.acceptedFile} />
                </div>
                <BitaButton
                  disabled={!filesBacktest?.name}
                  width="160px"
                  onClick={() => {
                    Papa.parse(filesBacktest, {
                      complete: results => {
                        if (
                          results.data.length > 1 &&
                          results.data[0][0].toUpperCase() === 'DATE' &&
                          results.data[0][1].toUpperCase() === 'VALUE'
                        ) {
                          setDataBacktest(results.data);
                        } else {
                          toast.error(
                            'Invalid file type structure. Please upload a file that matches the required format',
                          );
                        }
                      },
                      header: false,
                    });
                  }}
                  primary
                  height="35px"
                >
                  Upload File
                </BitaButton>
              </div>

              <p className={style.nameFile}>{filesBacktest && `(${filesBacktest?.name})`}</p>
            </div>
          </div>
          <div>{dataBacktest.length > 0 && <BacktestTable />}</div>
        </div>
      </div>
      {/* Basket Parameters section */}
      {/* {data && Object.keys(data ?? {}).length > 0 && ( */}
      {selectBasket && selectBasket.id && (
        <div className={style.compositionPorfolio}>
          <div className={style.compositionPorfolioTitle}>Basket Parameters</div>

          <div className={styles.contentWidget}>
            <div>Start Parameters</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <BitaInput
                    value={data?.name || ''}
                    type="text"
                    inputStyle={styles.nameInput}
                    title="Name"
                    externalTitle="Name"
                    placeholder="Name"
                    justify="flex-start"
                    handleChange={value => {
                      handleChange('name', value);
                    }}
                  />
                  <BitaInput
                    value={data?.description || ''}
                    type="text"
                    inputStyle={styles.nameInput}
                    title="Description"
                    externalTitle="Description"
                    placeholder="Description"
                    justify="flex-start"
                    handleChange={value => {
                      handleChange('description', value);
                    }}
                  />
                </div>
                <div
                  className={styles.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                    <BitaInput
                      disabled
                      value={selectBasket?.symbol ?? ''}
                      type="text"
                      inputStyle={styles.basketInput}
                      title="Ticker"
                      externalTitle="Ticker"
                    />
                  </div>
                </div>

                {(previousData?.divisor || data?.divisor) && !files?.name ? (
                  <div>
                    <div
                      className={styles.contentInput}
                      style={{
                        display: 'flex',
                        gap: '12px',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '18px',
                        }}
                      >
                        <div>Divisor</div>
                      </div>

                      <BitaInput
                        value={data?.divisor}
                        type="number"
                        inputStyle={!data?.divisor ? styles.needValidation : styles.basketInput}
                        title="Divisor"
                        placeholder="Enter Value"
                        lower_limit={0}
                        handleChange={val => {
                          handleChange('divisor', Number(val.replace(/,/g, '')));
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {files?.name && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        className={style.contentInput}
                        style={{
                          display: 'flex',
                          gap: '12px',
                          justifyContent: 'flex-start',
                          alignItems: 'baseline',
                          flexDirection: 'column',
                          marginRight: '40px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '18px',
                            width: 'auto',
                          }}
                        >
                          <div>Starting Value</div>
                          <div>
                            <Switcher.Switch
                              className={styles.customRadio}
                              onChange={() => {
                                setEnableDivisorOrStartingValue({
                                  statusStarting: !enableDivisorOrStartingValue.statusStarting,
                                  statusDivisor: !enableDivisorOrStartingValue.statusDivisor,
                                });
                                handleChange('divisor_determination_setup', false);
                                handleChange('divisor', 0);
                              }}
                              checked={enableDivisorOrStartingValue.statusStarting}
                            />
                          </div>
                        </div>
                        {enableDivisorOrStartingValue.statusStarting && (
                          <div style={{ marginBottom: '20px' }}>
                            <BitaSelect
                              warningIcon
                              selected={data?.divisor_determination_setup}
                              value={
                                validateFields(
                                  'divisor_determination_setup',
                                  data?.divisor_determination_setup,
                                )
                                  ? {
                                      value: data?.divisor_determination_setup,
                                      id: data?.divisor_determination_setup,
                                      name: data?.divisor_determination_setup,
                                    }
                                  : ''
                              }
                              label="Divisor Determination Point"
                              externalPlaceholder="Divisor Determination Point"
                              data={[
                                { value: 'PREVIOUS EOD', id: 'PREVIOUS EOD' },
                                { value: 'IMMEDIATELY', id: 'IMMEDIATELY' },
                              ]}
                              handleSelection={value => {
                                handleChange('divisor_determination_setup', value?.value);
                              }}
                              selectClassname={
                                !validateFields(
                                  'divisor_determination_setup',
                                  data?.divisor_determination_setup,
                                ) && style.needValidationSelect
                              }
                            />
                          </div>
                        )}
                        {/* {enableDivisorOrStartingValue.statusStarting && (
                        <BitaInput
                          value={data?.starting_value}
                          type="number"
                          inputStyle={
                            !data?.starting_value ? style.needValidation : style.basketInput
                          }
                          title="Start Value"
                          placeholder="Enter Value"
                          lower_limit={0}
                          handleChange={val => {
                            handleChange('starting_value', Number(val.replace(/,/g, '')));
                          }}
                        />
                      )} */}
                      </div>
                    </div>
                    <div
                      className={style.contentInput}
                      style={{
                        display: 'flex',
                        gap: '12px',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '18px',
                        }}
                      >
                        <div>Divisor</div>
                        <div>
                          <Switcher.Switch
                            className={styles.customRadio}
                            onChange={() =>
                              setEnableDivisorOrStartingValue({
                                statusStarting: !enableDivisorOrStartingValue.statusStarting,
                                statusDivisor: !enableDivisorOrStartingValue.statusDivisor,
                              })
                            }
                            checked={enableDivisorOrStartingValue.statusDivisor}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: '20px' }}>
                        {' '}
                        {enableDivisorOrStartingValue.statusDivisor && (
                          <BitaInput
                            value={data?.divisor}
                            type="number"
                            inputStyle={!data?.divisor ? styles.needValidation : styles.basketInput}
                            title="Divisor"
                            placeholder="Enter Value"
                            lower_limit={0}
                            handleChange={val => {
                              handleChange('divisor', Number(val.replace(/,/g, '')));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={styles.contentInput}
                  style={{
                    display: 'flex',
                    gap: '12px',
                    justifyContent: 'flex-start',
                    alignItems: 'baseline',
                    flexDirection: 'column',
                  }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.calculation_setup ?? ''}
                    value={
                      validateFields('calculation_setup', data?.calculation_setup)
                        ? {
                            value: data?.calculation_setup.replace('_', ' '),
                            id: data?.calculation_setup.replace('_', ' '),
                            name: data?.calculation_setup.replace('_', ' '),
                          }
                        : ''
                    }
                    externalPlaceholder="Calculation Frequency"
                    label="Calculation frequency"
                    data={[
                      { value: 'EOD Only', id: 'EOD' },
                      { value: 'EOD and Intra-day', id: 'EOD_INTRADAY' },
                    ]}
                    handleSelection={value => {
                      handleChange('calculation_setup', value?.id);
                      updateRequestCoverage(value?.id);
                    }}
                    selectClassname={
                      !validateFields('calculation_setup', data?.calculation_setup) &&
                      styles.needValidationSelect
                    }
                  />
                </div>

                <div
                  className={styles.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.calculation_zone}
                    value={{
                      value: timezone.find(item => item?.calculationZone === data?.calculation_zone)
                        ?.name,
                      id: data?.calculation_zone,
                      name: data?.calculation_zone,
                    }}
                    label="Calculation Zone"
                    externalPlaceholder="Calculation Zone"
                    data={timezone.filter(
                      item =>
                        item.value !== 'Eastern Asia Pacific' ||
                        hasValidUBSAPACOnly(user?.userdata.id_company) ||
                        hasValidBitaDomain(user?.userdata.email),
                    )}
                    handleSelection={value => {
                      handleChange('calculationZone', value?.value);
                    }}
                    selectClassname={
                      !validateFields('calculation_zone', data?.calculation_zone) &&
                      styles.needValidationSelect
                    }
                  />
                  <div
                    className={
                      errorCalendar
                        ? `needValidationSelect submitBasketCalendar `
                        : 'submitBasketCalendar'
                    }
                  >
                    <BitaCalendarDate
                      toUpperCaseTitle={false}
                      title="Launch Date"
                      select={value => {
                        if (value !== launchDate) {
                          setErrorCalendar(false);
                        }
                        setLaunchDate(value);
                      }}
                      maxDate={new Date('3000-12-12')}
                      minDate={new Date()}
                      date={launchDate}
                      tooltip="Select today's date or a future date"
                    />
                  </div>
                </div>
              </div>
              <div className={style.contentNoMetadata} style={{ width: '28%' }}>
                <div className={style.tableNoMetadata}>
                  <div style={{ borderBottom: '1px solid #c7c7c7' }}>Calculation Zone Details</div>
                  <div style={{ display: 'flex' }}>
                    <div>Timezone: </div> <div>{data?.timezone}</div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div>Calculation Start: </div> <div>{data?.calculation_open_time}</div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div>Calculation End: </div> <div>{data?.calculation_close_time}</div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div>Start Week Day: </div>{' '}
                    <div>{BUSINESS_DAYS[data?.calculation_start_week_day] || 'Monday'}</div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div>End Week Day: </div>{' '}
                    <div>{BUSINESS_DAYS[data?.calculation_end_week_day] || 'Friday'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.contentWidget}>
            <div>Return Type</div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '25px',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '25px',
                    justifyContent: 'flex-start',
                    alignItems: 'end',
                  }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.calculation_type} // This is return type
                    value={
                      validateFields('calculation_type', data?.calculation_type)
                        ? {
                            value: data?.calculation_type.replace('_', ' '),
                            id: data?.calculation_type,
                          }
                        : ''
                    }
                    label="Return calculation"
                    data={[
                      { value: 'NET RETURN', id: 'NET_RETURN' },
                      { value: 'TOTAL RETURN', id: 'TOTAL_RETURN' },
                      { value: 'PRICE RETURN', id: 'PRICE_RETURN' },
                    ]}
                    handleSelection={value => handleChange('calculation_type', value?.id)}
                    selectClassname={
                      !validateFields('calculation_type', data?.calculation_type) &&
                      styles.needValidationSelect
                    }
                  />
                  {data?.calculation_type === 'NET_RETURN' && (
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
                      <BitaSelect
                        width="330px"
                        warningIcon
                        selected={data?.withholding_tax_id ?? 'BITA Withholding Taxes'}
                        data={templateData?.map(template => {
                          return { value: template?.withholding_tax_name, id: template?.id };
                        })}
                        value={
                          validateFields('withholding_tax_id', data?.withholding_tax_id)
                            ? {
                                value: templateData?.find(
                                  item => item.id === data?.withholding_tax_id,
                                )?.withholding_tax_name,
                                id: data?.withholding_tax_id,
                              }
                            : {
                                value: 'BITA Withholding Taxes',
                                id: 'BITA Withholding Taxes',
                              }
                        }
                        externalPlaceholder="Withholding Taxes Template"
                        label="Withholding Taxes Template"
                        handleSelection={value => handleChange('withholding_tax_id', value?.id)}
                      />
                      <BitaButton
                        width="auto"
                        height="35px"
                        className={styles.withholdingTaxButton}
                        onClick={() => {
                          setOpenWithholdingModal(!openWithholingModal);
                        }}
                      >
                        Manage Withholding Taxes
                      </BitaButton>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '18px',
                    }}
                  >
                    <div>Annualized Daily Decrement</div>
                    <div>
                      <Switcher.Switch
                        className={styles.customRadio}
                        onChange={() => {
                          setIsActiveAnnualizedYearlyDecrement(!isActiveAnnualizedYearlyDecrement);
                          handleChange('percentage_value', null);
                        }}
                        checked={isActiveAnnualizedYearlyDecrement}
                      />
                    </div>
                  </div>

                  <div>
                    {isActiveAnnualizedYearlyDecrement && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BitaInput
                          value={data?.percentage_value ?? 0}
                          type="number"
                          upper_limit={100}
                          lower_limit={0}
                          inputStyle={styles.basketInput}
                          title="Percentage"
                          externalTitle="Percentage"
                          placeholder="Enter Percentage"
                          tooltip="Please indicate a percentage between 0 and 100"
                          handleChange={val => {
                            handleChange('percentage_value', val);
                          }}
                        />
                        <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                          Day-Count Conversion: 360 Days
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.contentWidget}>
            <div>Corporate Actions Methodology</div>
            <div>
              <div
                style={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'flex-start',
                  pointerEvents: 'none',
                }}
              >
                <div>BITA CORPORATE ACTIONS METHODOLOGY</div>
                <Switcher.Switch
                  className={styles.customRadio}
                  onChange={() => handleChange('corporate_actions_methodology', 'BITA')}
                  checked={data?.corporate_actions_methodology === 'BITA'}
                />
              </div>
              {/* ALL remember to remove the hardcode from corporate_actions_methodology  */}
              <div
                style={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'flex-start',
                  opacity: 0.5,
                  pointerEvents: 'none',
                }}
              >
                <div>CUSTOM METHODOLOGY</div>
                <Switcher.Switch
                  className={styles.customRadio}
                  onChange={() => handleChange('corporate_actions_methodology', 'CUSTOM')}
                  checked={data?.corporate_actions_methodology !== 'BITA'}
                />
              </div>
              {data?.corporate_actions_methodology &&
                data?.corporate_actions_methodology !== 'BITA' && (
                  <div style={{ marginTop: '10px' }}>
                    <BitaSelect
                      warningIcon
                      selected={data?.calculation_type}
                      value=""
                      label="Methodology"
                      data={[]}
                      handleSelection={value => handleChange('', value?.id)}
                      style={{ fontSize: 13 }}
                    />
                  </div>
                )}
            </div>
          </div>
          {data?.cash_constituent && (
            <div className={styles.contentWidget}>
              <div>Cash Constituents Configuration</div>
              <div
                className={styles.contentInputCash}
                style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
              >
                <BitaInput
                  value={data?.cash_constituent?.interest_rate_on_cash}
                  type="number"
                  style={{ with: '150px' }}
                  inputStyle={
                    !data?.cash_constituent?.interest_rate_on_cash
                      ? styles.needValidationCash
                      : styles.basketInputCash
                  }
                  title="Interest Rate on Cash"
                  externalTitle="Interest Rate on Cash"
                  placeholder="Enter Value"
                  lower_limit={0}
                  handleChange={val => {
                    handleChange('cash_constituent', {
                      ...data?.cash_constituent,
                      interest_rate_on_cash: val,
                    });
                  }}
                />
              </div>
            </div>
          )}
          {data?.calculation_type !== 'PRICE_RETURN' && (
            <div className={styles.contentWidget}>
              <div>Cash Distribution</div>
              <div
                className={styles.contentInput}
                style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
              >
                <BitaSelect
                  warningIcon
                  width="330px"
                  selected={data?.cash_distribution}
                  value={
                    validateFields('cash_distribution', data?.cash_distribution)
                      ? DATA_CASH_DISTRIBUTION.find(item => item.id === data?.cash_distribution)
                      : ''
                  }
                  label="Cash Distribution"
                  externalPlaceholder="Cash Distribution"
                  data={DATA_CASH_DISTRIBUTION}
                  handleSelection={value => handleChange('cash_distribution', value?.id)}
                  selectClassname={
                    !validateFields('cash_distribution', data?.cash_distribution) &&
                    styles.needValidationSelect
                  }
                />

                <BitaSelect
                  warningIcon
                  width="330px"
                  selected={data?.application_point}
                  label="Application Point"
                  externalPlaceholder="Application Point"
                  value={
                    validateFields('application_point', data?.application_point)
                      ? DATA_APPLICATION_POINT.find(item => item.id === data?.application_point)
                      : ''
                  }
                  data={DATA_APPLICATION_POINT}
                  handleSelection={value => handleChange('application_point', value?.id)}
                  selectClassname={
                    !validateFields('application_point', data?.application_point) &&
                    styles.needValidationSelect
                  }
                />
              </div>
            </div>
          )}

          <div className={styles.contentWidget}>
            <div>Currency</div>
            <div
              className={styles.contentInput}
              style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
            >
              <BitaSelect
                warningIcon
                selected={data?.currency}
                value={
                  validateFields('currency', data?.currency)
                    ? {
                        value: data?.currency,
                        id: data?.currency,
                      }
                    : ''
                }
                label="Currency"
                data={CURRENCIES_ARRAY.map(item => {
                  return { value: item, id: item };
                })}
                handleSelection={value => handleChange('currency', value?.id)}
                selectClassname={
                  !data?.currency || !validateFields('currency', data?.currency)
                    ? styles.needValidationSelect
                    : ''
                }
              />
            </div>
          </div>

          <div className={styles.contentWidget}>
            <div>Dissemination Options</div>
            <div
              className={styles.contentInput}
              style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
            >
              <BitaSelect
                warningIcon
                selected={data?.dissemination}
                value={
                  validateFields('dissemination', data?.dissemination)
                    ? {
                        value: data?.dissemination,
                        id: data?.dissemination,
                      }
                    : {
                        value: 'BITA Only',
                        id: 'BITA Only',
                      }
                }
                label="Dissemination"
                data={[
                  { value: 'BITA Only', id: 'BITA Only' },
                  { value: 'BITA + Bloomberg', id: 'BITA + Bloomberg', disabled: true },
                ]}
                handleSelection={value => handleChange('dissemination', value?.id)}
                style={{ fontSize: 13 }}
              />
            </div>
          </div>
        </div>
      )}
      {/* Apply Button */}
      <div
        className="contentButtonApply"
        style={{
          width: '100%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BitaButton onClick={handleApply} disabled={applyButtonValidations()} primary>
          Apply
        </BitaButton>
        {data?.meta_reference_data && applyButtonValidations() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '10px',
            }}
          >
            <BiSolidError className="icon-error" />
            <div>Cannot apply until errors are solved</div>
          </div>
        )}
        {data?.meta_reference_data &&
          (applyButtonValidations() || data?.meta_reference_data.length === 0) && (
            <div className="tooltipErrorApply">
              {existRequestCoverage() && (
                <p style={{ display: 'flex' }}>
                  {' '}
                  <BiSolidError className="icon-error" /> The basket contains some non-covered
                  instruments. Please review and make a decision.
                </p>
              )}
              {data?.meta_reference_data?.length === 0 && (
                <p style={{ display: 'flex' }}>
                  {' '}
                  <BiSolidError className="icon-error" />
                  The basket contains no instruments.
                </p>
              )}
              {!launchDate && (
                <p style={{ display: 'flex' }}>
                  {' '}
                  <BiSolidError className="icon-error" />
                  The launch date is missing.
                </p>
              )}
              {launchDate && errorCalendar && (
                <p style={{ display: 'flex' }}>
                  {' '}
                  <BiSolidError className="icon-error" />
                  The launch date is incorrect.
                </p>
              )}
              {validateData.map(item => (
                <div>
                  {!validarData(data, [item]) && (
                    <p style={{ display: 'flex' }}>
                      {' '}
                      <BiSolidError className="icon-error" />{' '}
                      {`${validateDataLabel[item]} is missing.`}
                    </p>
                  )}
                </div>
              ))}
              {!isDivisorOrStartingValueValid() && (
                <p style={{ display: 'flex' }}>
                  {' '}
                  <BiSolidError className="icon-error" /> Either the divisor or starting value is
                  missing/invalid. Please provide one of the two.
                </p>
              )}
            </div>
          )}
      </div>
      {/* Success Modal */}
      <BitaModal
        isModalOpen={showModal}
        onClick={() => {
          setShowModal(false);
          resetBasketData();
        }}
      >
        <div style={{ width: ' 600px ', minHeight: '300px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <span
              onClick={() => {
                setShowModal(false);
                resetBasketData();
              }}
              style={styleClose}
            >
              &times;
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={style.modalTextTitle}>Update successfully completed!</div>
            <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
              <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />
              <div>
                <p> Updated ID: {selectBasket?.id}</p>
                <p> Updated Name: {data?.name}</p>
                <p> Updated Status: Transmitted to BITA ACE </p>
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}
          >
            <BitaButton primaryWhite onClick={() => history.push('/')} height="35px" width="200px">
              Go to Home
            </BitaButton>
            <BitaButton
              primaryWhite
              onClick={() => {
                setShowModal(false);
                resetBasketData();
              }}
              height="35px"
              width="200px"
            >
              Update another basket
            </BitaButton>
          </div>
        </div>
      </BitaModal>
      {/* Confirm Modal */}
      <BitaModal isModalOpen={showModalConfirm} setModalState={() => setShowModalConfirm(false)}>
        <div
          style={{
            width: '850px',
            minHeight: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              UPDATE BASKET
            </h2>
            <span onClick={() => setShowModalConfirm(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px 0', textAlign: 'center', padding: '0px 5px' }}>
              Are you sure you want to update these parameters?
            </div>

            <div
              data-simplebar
              style={{ height: '460px', padding: '0px 5px' }}
              className="previewUpdateBasket"
            >
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <div
                className={tableStyles.tableContainer}
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <ShowPreview
                      data={data}
                      templateData={templateData}
                      orgPrefix={authOrgPrefix}
                      launchDate={launchDate}
                    />
                  </div>
                </div>

                {/* Summary table */}
                <div style={{ width: '100%' }}>
                  {data && (
                    <div className="tableUpdateBasket">
                      <BitaTable
                        data={renderRows(data?.meta_reference_data)}
                        search={false}
                        pagination
                        showFields={getColumns(data?.meta_reference_data)}
                        selectAction={() => {}}
                        CustomRowComponent={CustomRow}
                      />
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
                  <div style={{ width: '100%' }}>
                    {dataToshowOnSummary?.length > 0 && (
                      <div className="tableUpdateBasket">
                        <BitaTable
                          id="basket"
                          spacedRows
                          pagination
                          search={false}
                          equalColumnsWidth
                          selectAction={() => {}}
                          CustomRowComponent={CustomRow}
                          data={renderRowsUpdateTable(dataToshowOnSummary)}
                          showFields={getColumnsUpdateTable(dataToshowOnSummary)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div>{dataBacktest.length > 0 && <BacktestTable />}</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={handleSubmitButton}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalConfirm(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
      {/* showModalRequestCoverage Modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverage}
        setModalState={() => setShowModalRequestCoverage(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverage(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              We will send to the organization&apos;s e-mail the request for the following
              constituents
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <div className={styles.requestCoverageEntryModal}>
                      <>
                        {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                        <div>ISIN</div>
                        <div>MIC</div>
                        {authOrgPrefix !== 'UB' && <div>SEDOL</div>}
                      </>
                    </div>
                    {(data?.meta_reference_data || []).map(item => {
                      if (item?.type !== 'with_coverage') {
                        return (
                          <div className={styles.requestCoverageEntryModal}>
                            <>
                              {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                              <div>{item?.isin ?? '-'}</div>
                              <div>{item?.exchange ?? '-'}</div>
                              {authOrgPrefix !== 'UB' && <div>{item?.sedol ?? '-'}</div>}
                            </>
                          </div>
                        );
                      }
                      return '';
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  let body;

                  if (data?.identifier_type === 'sedol') {
                    const sedolsArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const sedols = sedolsArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol ?? null,
                        isin: coverage?.isin ?? null,
                        mic: coverage?.exchange ?? null,
                        type: coverage?.type ?? null,
                      };
                    });
                    body = {
                      user_email: user?.userdata?.email,
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: sedols,
                    };
                  }

                  if (data?.identifier_type === 'isin') {
                    const isinArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const isins = isinArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol ?? null,
                        isin: coverage?.isin ?? null,
                        mic: coverage?.exchange ?? null,
                        type: coverage?.type ?? null,
                      };
                    });
                    body = {
                      user_email: user?.userdata?.email,
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: isins,
                    };
                  }

                  setShowModalRequestCoverage(false);
                  coverageRequest(token, body)
                    .then(rsp => {
                      if (rsp.data.data.email_sent) {
                        setShowModalRequestCoverageSuccessful(true);
                      } else {
                        toast.error('We had a problem processing your request');
                      }
                      setShowModalRequestCoverage(false);
                    })
                    .catch(error => {
                      toast.error('We had a problem processing your request');
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalRequestCoverage(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
      {/* delete constituents modal Modal */}
      <BitaModal isModalOpen={showModalDelete} setModalState={() => setShowModalDelete(false)}>
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Delete Constituents
            </h2>
            <span onClick={() => setShowModalDelete(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Do you want to remove these constituents?
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={styles.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={styles.tableNoMetadata}>
                    <div className={styles.requestCoverageEntry}>
                      <>
                        {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                        <div>ISIN</div>
                        <div>MIC</div>
                        {authOrgPrefix !== 'UB' && <div>SEDOL</div>}
                        <div>COMPANY NAME</div>
                      </>
                    </div>
                    {(constituentsDelete || []).map(item => (
                      <div className={styles.requestCoverageEntry}>
                        <>
                          {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                          <div>{item?.isin ?? '-'}</div>
                          <div>{item?.exchange ?? '-'}</div>
                          {authOrgPrefix !== 'UB' && <div>{item?.sedol ?? '-'}</div>}
                          <div>{item?.company_name ?? '-'}</div>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  setShowModalDelete(false);
                  const arrayIdentifier = constituentsDelete.map(item => item.isin ?? item.sedol);
                  setData({
                    ...data,
                    meta_reference_data: [
                      ...data?.meta_reference_data?.filter(
                        item => !arrayIdentifier?.includes(item?.isin ?? item?.sedol),
                      ),
                    ],
                  });

                  setConstituentsDelete([]);
                  if (
                    data?.meta_reference_data?.filter(
                      item =>
                        !arrayIdentifier?.includes(item?.isin ?? item?.sedol) && item.shares === 0,
                    ).length === 0
                  ) {
                    setErrorReferenceData(false);
                  }
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Delete
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalDelete(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
      {/* sucessful coverage request modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverageSuccessful}
        setModalState={() => setShowModalRequestCoverageSuccessful(false)}
      >
        <div style={{ width: '600px ', minHeight: '130px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverageSuccessful(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Your request is being processed, we will contact you shortly.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowModalRequestCoverageSuccessful(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      <BitaModal isModalOpen={isDataLoading}>
        <div style={{ width: '300px ', minHeight: '130px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h2 style={{ margin: '0 0 18px 0', padding: '0', fontSize: '22px' }}>Loading Basket</h2>
            <BitaSpinner />
          </div>
        </div>
      </BitaModal>
      <VisualizationModal
        templateData={[
          { withholding_tax_name: 'BITA Withholding Taxes', id: 'default', org_id: 'BITA' },
          ...templateData,
        ]}
        updateTemplate={() => {
          getTemplates()
            .then(rsp => {
              const dataTax = [
                ...rsp?.data?.data,
                {
                  withholding_tax_name: 'BITA Withholding Taxes',
                  id: 'BITA Withholding Taxes',
                  custom: 'false',
                },
              ];
              setTemplateData(dataTax);
            })
            .catch(error => {
              toastInterceptor(error);
            });
        }}
        select={value => {
          handleChange('withholding_tax_id', value);
          getTemplates()
            .then(rsp => {
              const dataTax = [
                ...rsp?.data?.data,
                {
                  withholding_tax_name: 'BITA Withholding Taxes',
                  id: 'BITA Withholding Taxes',
                  custom: 'false',
                },
              ];
              setTemplateData(dataTax);
            })
            .catch(error => {
              toastInterceptor(error);
            });
        }}
        setModalState={setOpenWithholdingModal}
        isModalOpen={openWithholingModal}
      />
      <AddInstrumentModal
        isOpen={showModalAdd}
        cashOption={!data?.meta_reference_data?.find(item => item?.isin?.includes('CASH'))}
        onClose={() => setShowModalAdd(false)}
        listInstruments={data?.meta_reference_data}
        listMic={listMIC}
        addInstrument={newInstrument => {
          setData(prev => {
            if (newInstrument.exchange === 'CASH') {
              return {
                ...prev,
                cash_constituent: { interest_rate_on_cash: null },
                meta_reference_data: [...prev.meta_reference_data, newInstrument],
              };
            }
            return {
              ...prev,
              meta_reference_data: [...prev.meta_reference_data, newInstrument],
            };
          });
          setErrorReferenceData(true);
        }}
      />
    </div>
  );
};

export default UpdateBasket;
